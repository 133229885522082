@font-face 
{
  font-family: 'qoran';
  src: url('font/Bustani.eot?#') format('eot'), url('font/Bustani.woff') format('woff');
}

@font-face 
{
  font-family: 'iranS';
  src: url('font/IRANSansWeb.eot?#') format('eot'), url('font/IRANSansWeb.woff') format('woff'), url('font/IRANSansWeb.woff2') format('woff2'), url('font/IRANSansWeb.ttf') format('ttf');
}

body 
{
  min-height: 100vh;
  /* background-image: linear-gradient( 135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%); */
}

#waiting
{
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(255, 244, 244, 0.7);
  z-index: 10;
}

#waiting img
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hidden
{
  display:none;
}

#quranHead
{
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  max-height: 200px;
}

.btn , .badge
{
  font-family: iranS;
  width: 100%;
  margin-top: 20px;
}

.badge
{
  margin-bottom: 0;
}

.bg-inline
{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bg-inline .badge
{
  margin-top: 0;
  border-radius: 0;
  width: 100%;
  text-align: center;
}

.form-control
{
  font: 16px iranS;
}

#quranHead2
{
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 50px;
}

#quranHead2 div
{
  color: skyblue;
  font-family:  qoran;
  font-size: 26px;
  text-align: center;
  padding: 10px;
}

#quranHead3
{
  background-size: 100%;
  height: 60px;
}

#quranHead4
{
  background-size: cover;
  width: 100%;
}

#quranFoot2
{
  background-size: cover;
  width: 100%;
}

#quranHead3 > div
{
  padding-top: 50px;
}

#txtHead
{
  font: 20px qoran;
  text-align: center;
  color: #E8A761;
}

#txt
{
  font: 16px iranS;
  color: white;
  text-align: center;
}

#quran
{
  margin: 0 auto;
  padding: 80px;
  background-size: 100%;
  min-height: 200px;
  width: 100%;
}

#qStart
{
  font: 40px qoran;
  color: #E8A761;
  text-align: center;
}

#qEnd
{
  font: 22px qoran;
  color: whitesmoke;
  position: absolute;
  display: flex;
  background-size: 100%;
  width: 100%;
  height: 50px;
}

#qEnd > div
{
  text-align: center;
  flex: 1;
  
}

#qBody
{
  position: relative;
}

#arab
{
  position: absolute;
  font-family: qoran;
  font-size: 36px;
  line-height: 100px;
  color: white;
  text-align: center;
}

#tran
{
  padding-top: 50px;
  line-height: 100px;
  font-size: 14px;
  text-align: center;
  font-family:  iranS;
  color: coral;
  position: absolute;
}

/* All Settings */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}


/*Besmellah Error*/
@media only screen and (max-width: 768px) and (min-width: 490px) {
  #quranHead3
  {
    background-size: 100%;
    height: 40px;
  }
}
@media only screen and (max-width: 490px){
  #quranHead2
  {
    background-size: 100%;
    height: 30px;
  }

  #quranHead2 div
  {
    font-size: 15px;
  }

  #quranHead3
  {
    background-size: 100%;
    height: 30px;
  }
}

/* main font size */
@media only screen and (min-width: 740px){
  #arab
  {
    position: absolute;
    font-family: qoran;
    font-size: 32px;
    line-height: 100px;
    color: white;
    text-align: center;
  }
  
  #tran
  {
    padding-top: 50px;
    line-height: 100px;
    font-size: 16px;
    text-align: center;
    font-family:  iranS;
    color: coral;
    position: absolute;
  }
}